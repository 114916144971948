import React, { useState, useCallback, useEffect } from 'react'
import { Banner, Button, Card, ColorPicker, FormLayout, FooterHelp, Icon, Layout, Loading, Link, Page, Popover, RadioButton, Select, SkeletonBodyText, TextField, TextStyle, Toast } from '@shopify/polaris'
import { useHistory } from 'react-router-dom'
import { gql } from "apollo-boost";
import { useQuery, useMutation } from '@apollo/react-hooks';
import { ErrorState } from '../components'
import { ColorPickerIcon } from '../components'

import {
  AppsMajorMonotone,
  BalanceMajorMonotone,
  BehaviorMajorMonotone,
  DnsSettingsMajorMonotone,
  ImageWithTextOverlayMajorMonotone,
  ColorsMajorMonotone,
  ViewMajorMonotone
} from '@shopify/polaris-icons';

const fonts = [
  { label: 'Use website default', value: 'inherit' },
  { label: 'Open Sans', value: 'open-sans' },
  { label: 'Lobster', value: 'lobster' }
]

const sizeUnits = [
  { label: 'em', value: 'em' },
  { label: 'px', value: 'px' },
  { label: 'pt', value: 'pt' }
]

const THEME_SETTINGS = gql`
query {
  shopSettings {
    enabled
    themeSettings {
      inherit
      buttonColor
      buttonTextColor
      headlineFontColor
      headlineFontSize {
        fontSize
        fontSizeUnit
      }
      bodyFontColor
      bodyFontSize {
        fontSize
        fontSizeUnit
      }
    }
  }
}
`

const SAVE_THEME_SETTINGS = gql`
mutation UpdateThemeSettings($ThemeSettings: ThemeSettingsInput!) {
  updateThemeSettings(themeSettings: $ThemeSettings) {
    result {
      success
      message
    }
  }
}
`


export const ThemeSettings: React.SFC = () => {
  const [unsavedChanges, setUnsavedChanges] = useState(false)
  const [inherit, setInherit] = useState(true)
  const [buttonColor, setButtonColor] = useState('')
  const [buttonTextColor, setButtonTextColor] = useState('')
  const [showColorPicker, setShowColorPicker] = useState(false)

  const [headlineFontFamily, setHeadlineFontFamily] = useState('inherit')
  const [headlineFontColor, setHeadlineFontColor] = useState('#000000')
  const [headlineFontSize, setHeadlineFontSize] = useState('1');
  const [headlineFontSizeUnit, setHeadlineFontSizeUnit] = useState('em')
  const [bodyFontFamily, setBodyFontFamily] = useState('inherit')
  const [bodyFontColor, setBodyFontColor] = useState('#000000')
  const [bodyFontSize, setBodyFontSize] = useState('1');
  const [bodyFontSizeUnit, setBodyFontSizeUnit] = useState('em')

  const { loading, error, data } = useQuery(THEME_SETTINGS);
  const [saveSettings, {loading: mutationLoading, error: mutationError, data: mutationData }] = useMutation(SAVE_THEME_SETTINGS);

  useEffect(() => {
    if (window.analytics) {
        window.analytics.page('Theme settings');
    }
  })

  useEffect(() => {
    if (typeof data?.shopSettings?.themeSettings?.inherit === 'boolean') {
      console.log('setting inherit')
      setInherit(data.shopSettings.themeSettings.inherit);
    }
    if (data?.shopSettings?.themeSettings?.buttonColor) {
      setButtonColor(data.shopSettings.themeSettings.buttonColor);
    }
    if (data?.shopSettings?.themeSettings?.buttonTextColor) {
      setButtonTextColor(data.shopSettings.themeSettings.buttonTextColor);
    }
    if (data?.shopSettings?.themeSettings?.headlineFontColor) {
      setHeadlineFontColor(data.shopSettings.themeSettings.headlineFontColor);
    }
    if (data?.shopSettings?.themeSettings?.headlineFontSize?.fontSize) {
      setHeadlineFontSize(data.shopSettings.themeSettings.headlineFontSize.fontSize);
    }
    if (data?.shopSettings?.themeSettings?.headlineFontSize?.fontSizeUnit) {
      setHeadlineFontSizeUnit(data.shopSettings.themeSettings.headlineFontSize.fontSizeUnit);
    }
    if (data?.shopSettings?.themeSettings?.bodyFontColor) {
      setBodyFontColor(data.shopSettings.themeSettings.bodyFontColor);
    }
    if (data?.shopSettings?.themeSettings?.bodyFontSize?.fontSize) {
      setBodyFontSize(data.shopSettings.themeSettings.bodyFontSize.fontSize);
    }
    if (data?.shopSettings?.themeSettings?.bodyFontSize?.fontSizeUnit) {
      setBodyFontSizeUnit(data.shopSettings.themeSettings.bodyFontSize.fontSizeUnit);
    }
  }, [data])

  useEffect(() => {
    if (
      data?.shopSettings?.themeSettings?.inherit === inherit

      && data?.shopSettings?.themeSettings?.buttonColor === buttonColor
      && data?.shopSettings?.themeSettings?.buttonTextColor === buttonTextColor

      && data?.shopSettings?.themeSettings?.headlineFontColor === headlineFontColor
      && data?.shopSettings?.themeSettings?.headlineFontSize?.fontSize === Number(headlineFontSize)
      && data?.shopSettings?.themeSettings?.headlineFontSize?.fontSizeUnit === headlineFontSizeUnit

      && data?.shopSettings?.themeSettings?.bodyFontColor === bodyFontColor
      && data?.shopSettings?.themeSettings?.bodyFontSize?.fontSize === Number(bodyFontSize)
      && data?.shopSettings?.themeSettings?.bodyFontSize?.fontSizeUnit === bodyFontSizeUnit
    ) {
      setUnsavedChanges(false)
    } else {
      setUnsavedChanges(true)
    }
  }, [inherit, buttonColor, buttonTextColor, headlineFontColor, headlineFontSize, headlineFontSizeUnit, bodyFontColor, bodyFontSize, bodyFontSizeUnit])

  const history = useHistory()

  const handleInheritChange = useCallback(
    (_checked, newValue) => newValue === 'TRUE' ? setInherit(true) : setInherit(false),
    []
  )

  const handleButtonColorChange = useCallback(
    (value) => setButtonColor(value),
    []
  )

  const handleButtonTextColorChange = useCallback(
    (value) => setButtonTextColor(value),
    []
  )

  const toggleShowColorPicker = useCallback(
    () => setShowColorPicker((popoverActive) => !popoverActive),
    [],
  );

  const handleHeadlineFontChange = useCallback(
    (value) => setHeadlineFontFamily(value),
    [],
  );

  const handleHeadlineFontSizeChange = useCallback(
    (value) => setHeadlineFontSize(value),
    [],
  );

  const handleHeadlineFontSizeUnitChange = useCallback(
    (value) => setHeadlineFontSizeUnit(value),
    []
  )

  const handleHeadlineColorChange = useCallback(setHeadlineFontColor, [])

  const handleBodyFontChange = useCallback(
    (value) => setBodyFontFamily(value),
    [],
  );

  const handleBodyFontSizeChange = useCallback(
    (value) => setBodyFontSize(value),
    [],
  );

  const handleBodyFontSizeUnitChange = useCallback(
    (value) => setBodyFontSizeUnit(value),
    []
  )

  const handleBodyColorChange = useCallback(setBodyFontColor, [])

  if (error) return <ErrorState error={{component: "ThemeSettings", ...error }} />

  const previewUrl = `https://${sessionStorage.getItem('shopName')}/?poppy-preview`
    + `&button-color=${encodeURIComponent(buttonColor)}`
    + `&button-text-color=${encodeURIComponent(buttonTextColor)}`
    + (!inherit ? `&headline-font-color=${encodeURIComponent(headlineFontColor)}` : '')
    + (!inherit ? `&headline-font-size=${encodeURIComponent(headlineFontSize) + headlineFontSizeUnit}` : '')
    + (!inherit ? `&body-font-color=${encodeURIComponent(bodyFontColor)}` : '')
    + (!inherit ? `&body-font-size=${encodeURIComponent(bodyFontSize) + bodyFontSizeUnit}` : '')

  const RenderButtonColorPickerIcon = <Icon source={<ColorPickerIcon fill={buttonColor}/>}/>
  const RenderButtonTextColorPickerIcon = <Icon source={<ColorPickerIcon fill={buttonTextColor}/>}/>
  const RenderHeadlineFontColorIcon = <Icon source={<ColorPickerIcon fill={headlineFontColor}/>}/>
  const RenderBodyFontColorIcon = <Icon source={<ColorPickerIcon fill={bodyFontColor}/>}/>


  const RenderColorPickerButton = (<Button
    accessibilityLabel="Show color picker"
    icon={RenderButtonColorPickerIcon}
    onClick={toggleShowColorPicker}
  />)

  const saveChanges = () => {
    saveSettings({
      variables: {
        ThemeSettings: {
          inherit,
          buttonColor,
          buttonTextColor,
          headlineFontColor,
          headlineFontSize: { fontSize: Number(headlineFontSize), fontSizeUnit: headlineFontSizeUnit },
          bodyFontColor,
          bodyFontSize: {fontSize: Number(bodyFontSize), fontSizeUnit: bodyFontSizeUnit}
        }
      }
    })
    setUnsavedChanges(false)
  }

  return (
    <Page
      title="Theme settings"
      icon="gear"
    >
      {loading || mutationLoading ? (<Loading />) : undefined}
        <Page
          title="Theme settings"
          primaryAction={{ loading: loading || mutationLoading, disabled:  error || !unsavedChanges, content: data?.shopSettings?.enabled ? 'Publish settings' : 'Save settings', onAction: () => saveChanges() }}
          secondaryActions={[
            { content: 'Layout', onAction: () => history.push('/a/s/layout'), icon: ImageWithTextOverlayMajorMonotone},
            { content: 'Trigger', onAction: () => history.push('/a/s/trigger'), icon: BehaviorMajorMonotone },
            { content: 'Theme', onAction: () => history.push('/a/s/theme'), icon: ColorsMajorMonotone, disabled: true },
            { content: 'Advanced', onAction: () => history.push('/a/s/advanced'), icon: DnsSettingsMajorMonotone },
            // { content: 'Integrations', onAction: () => history.push('/a/s/integrations'), icon: AppsMajorMonotone },
            { content: 'Preview popup', icon: ViewMajorMonotone, url: previewUrl, external: true }
          ]}
          breadcrumbs={[{content: 'Dashboard', onAction: () => history.push('/a/dashboard') }]}
          pagination={{
            hasPrevious: true,
            onPrevious: () => history.push('/a/s/trigger'),
            previousTooltip: 'Trigger settings',
            hasNext: true,
            onNext: () => history.push('/a/s/advanced'),
            nextTooltip: 'Advanced settings'
          }}
          forceRender={true}
          separator
        >
        {mutationData
          ? (<Toast content="Settings saved" onDismiss={() => null}/>)
          : null
        }
        {mutationError
          ? (<Toast content="Error saving settings" error onDismiss={() => null} />)
          : null
        }
        <Layout>
          <Layout.AnnotatedSection title="Custom styling" description="Inherit styles from your Shopify theme, or customize the widget.">
            <Card sectioned>
              <FormLayout>
                <RadioButton
                  label="Use your Shopify theme's styling for the popup widget (recommended)"
                  helpText="Inheriting styles from your theme keeps styling consistant and decreases page load times, which has a direct impact on key performance metrrics."
                  checked={inherit}
                  id="TRUE"
                  name="inherit"
                  onChange={handleInheritChange}
                />
                <RadioButton
                  label="Override theme styling with custom styles for the popup widget"
                  helpText="This offers you more control over the look of your popup, but increases page load times which negatively impacts key performance metrics like conversion rate and page rank"
                  id="FALSE"
                  name="inherit"
                  checked={!inherit}
                  onChange={handleInheritChange}
                />
              </FormLayout>
            </Card>
          </Layout.AnnotatedSection>
          <Layout.AnnotatedSection title="Button colors" description="Customize the look and appearance for the submission form.">
            <Card sectioned>
              <FormLayout>
                {loading
                  ? (<SkeletonBodyText lines={1}/>)
                  : (
                    <TextField
                      label="Button fill color"
                      value={buttonColor}
                      onChange={handleButtonColorChange}
                      suffix={RenderButtonColorPickerIcon}
                      pattern="^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$"
                      error={buttonColor.match("^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$") ? false : "Please enter a valid hex color"}
                    />
                  )
                }
                {loading
                  ? (<SkeletonBodyText lines={1}/>)
                  : (
                    <TextField
                      label="Button text color"
                      value={buttonTextColor}
                      onChange={handleButtonTextColorChange}
                      suffix={RenderButtonTextColorPickerIcon}
                      pattern="^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$"
                      error={buttonColor.match("^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$") ? false : "Please enter a valid hex color"}
                    />
                  )
                }
              </FormLayout>
            </Card>
          </Layout.AnnotatedSection>
          <Layout.AnnotatedSection title="Headline settings" description="Customize the look and appearance for the headline.">
            <Card sectioned>
              <FormLayout>
                {/*
                  <Select
                  label="Font family (coming soon)"
                  options={fonts}
                  onChange={handleHeadlineFontChange}
                  value={headlineFontFamily}
                  disabled
                  />
                  */}
                  {loading
                    ? (<SkeletonBodyText lines={1}/>)
                    : (
                      <TextField
                        label="Font color"
                        value={inherit ? 'Use website default' : headlineFontColor}
                        onChange={inherit ? () => null : handleHeadlineColorChange}
                        disabled={inherit}
                        suffix={RenderHeadlineFontColorIcon}
                        pattern="^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$"
                        error={headlineFontColor.match("^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$") ? false : "Please enter a valid hex color"}
                      />
                    )
                  }
                {loading
                  ? (<SkeletonBodyText lines={1}/>)
                  : (
                    <TextField
                      label="Font size"
                      value={inherit ? 'Use website default' : headlineFontSize}
                      type={inherit ? undefined : "number"}
                      onChange={inherit ? () => null : handleHeadlineFontSizeChange}
                      disabled={inherit}
                      connectedRight={
                          <Select
                            value={headlineFontSizeUnit}
                            label="Weight unit"
                            onChange={handleHeadlineFontSizeUnitChange}
                            labelHidden
                            options={['em', 'px', 'pt']}
                            disabled={inherit}
                          />
                        }
                    />
                  )
                }
              </FormLayout>
            </Card>
          </Layout.AnnotatedSection>
          <Layout.AnnotatedSection title="Description settings" description="Customize the look and appearance for the description text.">
            <Card sectioned>
              <FormLayout>
                {/*
                  <Select
                    label="Font family (coming soon)"
                    options={fonts}
                    onChange={handleBodyFontChange}
                    value={inherit ? 'inherit' : bodyFontFamily}
                    disabled
                  />
                */}
                {loading
                  ? (<SkeletonBodyText lines={1}/>)
                  : (
                    <TextField
                      label="Font color"
                      value={inherit ? 'Use website default' : bodyFontColor}
                      onChange={inherit ? () => null : handleBodyColorChange}
                      disabled={inherit}
                      suffix={RenderBodyFontColorIcon}
                      pattern="^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$"
                      error={bodyFontColor.match("^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$") ? false : "Please enter a valid hex color"}
                    />
                  )
                }
                {loading
                  ? (<SkeletonBodyText lines={1}/>)
                  : (
                    <TextField
                      label="Font size"
                      value={inherit ? 'Use website default' : bodyFontSize}
                      type={inherit ? undefined : "number"}
                      onChange={inherit ? () => null: handleBodyFontSizeChange}
                      disabled={inherit}
                      connectedRight={
                          <Select
                            value={bodyFontSizeUnit}
                            label="Weight unit"
                            onChange={handleBodyFontSizeUnitChange}
                            labelHidden
                            options={['em', 'px', 'pt']}
                            disabled={inherit}
                          />
                        }
                    />
                  )
                }
              </FormLayout>
            </Card>
          </Layout.AnnotatedSection>
        </Layout>
        </Page>
        <FooterHelp>
          Learn more about{' '}
          <Link external={true} url="https://docs.ventures-adventures.com/poppy-exit-intent-popup/settings/theme-settings">
            Layout settings
          </Link> and <Link external={true} url="https://docs.ventures-adventures.com/poppy-exit-intent-popup/settings/theme-settings/custom-css">Custom CSS</Link>
        </FooterHelp>
    </Page>
  )
}
