import React, { useEffect } from 'react'
import { Route, match } from 'react-router-dom'
import { ApolloProvider } from '@apollo/react-hooks'
import { ApolloClient } from 'apollo-client'
import { onError } from 'apollo-link-error'
import { ApolloLink } from 'apollo-link'
import { createUploadLink } from 'apollo-upload-client'
import { RetryLink } from "apollo-link-retry"
import { InMemoryCache } from 'apollo-cache-inmemory'
import { AppProvider } from '@shopify/polaris'
import { Dashboard, SettingsParent } from '../pages'
import { AuthReloader } from '../components'

type Props = {
  token: string | false | null;
  shopName: string | false | null;
  match: match;
}

const { REACT_APP_SERVER_URL, REACT_APP_SHOPIFY_KEY } = process.env;

export const AuthRoutes: React.SFC<Props> = ({ token, shopName, match }) => {
  const client = new ApolloClient({
    link: ApolloLink.from([
      new RetryLink(),
      onError(({ graphQLErrors, networkError }) => {
        if (graphQLErrors)
          graphQLErrors.forEach(({ message, locations, path }) =>
            console.error(
              `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
            ),
          );
        if (networkError) console.log(`[Network error]: ${networkError}`);
      }),
      createUploadLink({
        uri: `${REACT_APP_SERVER_URL}/graphql`,
        headers: { Authorization: token },
      })
    ]),
    cache: new InMemoryCache(),
  });

  const sessionShopName: string | null = typeof shopName === 'string'
    ? shopName
    : typeof sessionStorage.getItem('shopName') === 'string'
      ? sessionStorage.getItem('shopName')
      : 'FALSE';
  useEffect(() => {
    if (window.analytics) {
      window.analytics.identify(sessionShopName, { displayName: sessionShopName })
    }
  }, [shopName])
  return (
    <AppProvider apiKey={REACT_APP_SHOPIFY_KEY} shopOrigin={typeof sessionShopName === 'string'? sessionShopName : 'FALSE'}>
      <ApolloProvider client={client as any}>
        <AuthReloader token={token} shopName={shopName}>
          <Route path={`${match.url}/dashboard`} render={() => <Dashboard />} />
          <Route path={`${match.url}/s`} render={({match}) => <SettingsParent match={match} />} />
        </AuthReloader>
      </ApolloProvider>
    </AppProvider>
  )
}
