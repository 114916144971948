import React, { useState, useCallback, useEffect } from 'react'
import { Badge, Caption, Card, DropZone, FooterHelp, Icon, Layout, Loading, Link, Page, PageActions, ProgressBar, ResourceList, SkeletonBodyText, Spinner, Stack, TextContainer, TextField, TextStyle, Thumbnail, Toast } from '@shopify/polaris'
import { ImageMajorMonotone } from '@shopify/polaris-icons'
import { useHistory } from 'react-router-dom'
import { gql } from "apollo-boost";
import { useQuery, useMutation } from '@apollo/react-hooks';
import { ErrorState } from '../components'

import {
  AppsMajorMonotone,
  BalanceMajorMonotone,
  BehaviorMajorMonotone,
  DnsSettingsMajorMonotone,
  ImageWithTextOverlayMajorMonotone,
  ColorsMajorMonotone,
  ViewMajorMonotone
} from '@shopify/polaris-icons';

const initFiles: File[] = [];

const LAYOUT_SETTINGS = gql`
query {
  shopSettings {
    enabled
    layoutSettings {
      template
      headline
      description
      image
    }
  }
}
`

const MEDIA = gql`
query {
  media {
    nodes {
      filename
      externalId
      width
      height
    }
  }
}
`

const SAVE_LAYOUT_SETTINGS = gql`
mutation UpdateLayoutSettings($LayoutSettings: LayoutSettingsInput!) {
  updateLayoutSettings(layoutSettings: $LayoutSettings) {
    result {
      success
      message
    }
  }
}
`

const UPLOAD_SINGLE_FILE = gql`
mutation UploadFile($file: Upload!) {
  uploadFile(file: $file) {
    filename
  }
}
`



export const LayoutSettings: React.SFC = () => {
  const [unsavedChanges, setUnsavedChanges] = useState(false)
  const [template, setTemplate] = useState('LOADING');
  const [headline, setHeadline] = useState('')
  const [description, setDescription] = useState('')
  const [selectedImage, setSelectedImage] = useState('')
  const [stackedHover, setStackedHover] = useState(false)
  const [sideBySideHover, setSideBySideHover] = useState(false)
  const [uploadLoading, setUploadLoading] = useState(false)
  const [files, setFiles] = useState(initFiles)
  const history = useHistory()
  const { loading, error, data } = useQuery(LAYOUT_SETTINGS);
  const { loading: mediaLoading, error: mediaError, data: mediaData, refetch: refetchMedia } = useQuery(MEDIA);
  const [saveSettings, {loading: mutationLoading, error: mutationError, data: mutationData }] = useMutation(SAVE_LAYOUT_SETTINGS);
  const [uploadFile] = useMutation(UPLOAD_SINGLE_FILE);

  useEffect(() => {
    if (window.analytics) {
        window.analytics.page('Layout settings');
    }
  })

  useEffect(() => {
    if (data?.shopSettings?.layoutSettings?.template) {
      setTemplate(data.shopSettings.layoutSettings.template);
    }
    if (data?.shopSettings?.layoutSettings?.headline) {
      setHeadline(data.shopSettings.layoutSettings.headline);
    }
    if (data?.shopSettings?.layoutSettings?.description) {
      setDescription(data.shopSettings.layoutSettings.description);
    }
    if (data?.shopSettings?.layoutSettings?.image) {
      setSelectedImage(data.shopSettings.layoutSettings.image);
    }
  }, [data])

  useEffect(() => {
    if (
      data?.shopSettings?.layoutSettings?.template === template
      && data?.shopSettings?.layoutSettings?.image === selectedImage
      && data?.shopSettings?.layoutSettings?.headline === headline
      && data?.shopSettings?.layoutSettings?.description === description
    ) {
      setUnsavedChanges(false)
    } else {
      setUnsavedChanges(true)
    }
  }, [template, selectedImage, headline, description])

  const handleHeadlineChange = useCallback(
    (value) => setHeadline(value),
    []
  )

  const handleDescriptionChange = useCallback(
    (value) => setDescription(value),
    []
  )

  const previewUrl = `https://${sessionStorage.getItem('shopName')}/?poppy-preview&template=${encodeURIComponent(template)}&headline=${encodeURIComponent(headline)}&image=${encodeURIComponent(selectedImage)}&description=${encodeURIComponent(description)}`

  const handleDropZoneDrop = useCallback(
    async (_dropFiles, acceptedFiles, _rejectedFiles) => {
      try {
        setUploadLoading(true)
        if (acceptedFiles.length > 0) {
          const file = acceptedFiles[0]
          await uploadFile({ variables: { file }})
          refetchMedia()
          setUploadLoading(false)
          return;
        }
      } catch (e) {
        console.log(e)
        setUploadLoading(false)
        return;
      }
    },
    []
  )

  if (error) return <ErrorState error={{component: "LayoutSettings", ...error }} />

  const validImageTypes = ['image/gif', 'image/jpeg', 'image/png'];
  const uploadedFiles = files.length > 0 && (
    <Stack vertical>
      {files.map((file, index) => (
        <Stack alignment="center" key={index}>
          <Thumbnail
            size="small"
            alt={file.name}
            source={
              validImageTypes.indexOf(file.type) > 0
                ? window.URL.createObjectURL(file)
                : 'https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304'
            }
          />
          <div>
            {file.name} <Caption>{file.size} bytes</Caption>
          </div>
        </Stack>
      ))}
    </Stack>
  );

  const saveChanges = () => {
    setUnsavedChanges(false)
    saveSettings({ variables: { LayoutSettings: { template, headline, description, image: selectedImage }}})
  }

  return (
    <Page
      title="Layout settings"
      icon="gear"
    >
      {loading || mutationLoading ? (<Loading />) : undefined}
        <Page
          title="Layout settings"
          primaryAction={{ loading: loading || mutationLoading, disabled:  error || !unsavedChanges, content: data?.shopSettings?.enabled ? 'Publish settings' : 'Save settings', onAction: () => saveChanges() }}
          secondaryActions={[
            { content: 'Layout', onAction: () => history.push('/a/s/layout'), disabled: true, icon: ImageWithTextOverlayMajorMonotone},
            { content: 'Trigger', onAction: () => history.push('/a/s/trigger'), icon: BehaviorMajorMonotone },
            { content: 'Theme', onAction: () => history.push('/a/s/theme'), icon: ColorsMajorMonotone },
            { content: 'Advanced', onAction: () => history.push('/a/s/advanced'), icon: DnsSettingsMajorMonotone },
            // { content: 'Integrations', onAction: () => history.push('/a/s/integrations'), icon: AppsMajorMonotone },
            { content: 'Preview popup', icon: ViewMajorMonotone, url: previewUrl, external: true }
          ]}
          breadcrumbs={[{content: 'Dashboard', onAction: () => history.push('/a/dashboard') }]}
          pagination={{
            hasPrevious: false,
            hasNext: true,
            onNext: () => history.push('/a/s/trigger'),
            nextTooltip: 'Trigger settings'
          }}
          forceRender={true}
          separator
        >
        {mutationData
          ? (<Toast content="Settings saved" onDismiss={() => null}/>)
          : null
        }
        {mutationError
          ? (<Toast content="Error saving settings" error onDismiss={() => null} />)
          : null
        }
        <Layout>
          <Layout.AnnotatedSection title="Popup layout" description={<TextContainer>There are two options for a popup, 'Side by Side' and 'Stacked'<br/><br/><Link monochrome url={previewUrl} external>Preview popup</Link></TextContainer>}>
            <Card sectioned>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
              {/* BEGIN: Left side */}
              <div
                style={{
                  flexGrow: 1,
                  cursor: 'pointer',
                  border: template === 'SIDE_BY_SIDE' ? '0.2rem solid #5c6ac4' : '0.2rem dashed #dfe3e8',
                  background: template !== 'SIDE_BY_SIDE' && sideBySideHover ? '#F4F6F8' : undefined,
                  height: '12em',
                  marginRight: '0.5em',
                  borderRadius: '3px',
                  display: 'flex',
                  flexDirection: 'column',
                  padding: '0.5em',
                  // boxShadow: '0 0 0 2px #5c6ac4'
                }}
                onClick={() => setTemplate('SIDE_BY_SIDE')}
                onMouseEnter={() => setSideBySideHover(true)}
                onMouseLeave={() => setSideBySideHover(false)}
              >
                <div style={{
                  flexGrow: 3,
                  display: 'flex',
                  flexDirection: 'row'
                }}>
                  <div style={{
                    flexGrow: 1,
                    flexBasis: 1,
                    border: '1px solid #dedede',
                    borderRadius: '1px',
                    marginBottom: '0.25em',
                    marginRight: '0.25em',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}/>
                  <div style={{
                    flexGrow: 1,
                    flexBasis: 1,
                    border: '1px solid #dedede',
                    borderRadius: '1px',
                    marginBottom: '0.25em',
                    marginLeft: '0.25em',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}>
                    {loading || mutationLoading
                      ? <Spinner accessibilityLabel="Loading..." size="small" color="inkLightest" />
                      : <Icon source={ImageMajorMonotone} />
                    }
                  </div>
                </div>
                <div style={{
                  flexGrow: 1,
                  border: '1px solid #dedede',
                  borderRadius: '1px',
                  marginTop: '0.25em'
                }}>
                </div>
              </div>
              {/* END: Left side */}
              {/* BEGIN: Right side */}
              <div
                style={{
                  flexGrow: 1,
                  cursor: 'pointer',
                  border: template === 'STACKED' ? '0.2rem solid #5c6ac4' : '0.2rem dashed #dfe3e8',
                  background: template !== 'STACKED' && stackedHover ? '#F4F6F8' : undefined,
                  height: '12em',
                  marginLeft: '0.5em',
                  borderRadius: '3px',
                  display: 'flex',
                  flexDirection: 'column',
                  padding: '0.5em',
                  // boxShadow: '0 0 0 2px #5c6ac4'
                }}
                onClick={() => setTemplate('STACKED')}
                onMouseEnter={() => setStackedHover(true)}
                onMouseLeave={() => setStackedHover(false)}
              >
                <div style={{
                  flexGrow: 5,
                  flexBasis: 1,
                  border: '1px solid #dedede',
                  borderRadius: '1px',
                  marginBottom: '0.25em',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}>
                  {loading || mutationLoading
                    ? <Spinner accessibilityLabel="Loading..." size="small" color="inkLightest" />
                    : <Icon source={ImageMajorMonotone} />
                  }
                </div>
                <div style={{
                  flexGrow: 1,
                  flexBasis: 1,
                  border: '1px solid #dedede',
                  borderRadius: '1px',
                  marginBottom: '0.25em',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}/>
                <div style={{
                  flexGrow: 1,
                  border: '1px solid #dedede',
                  borderRadius: '1px',
                }}>
                </div>
              </div>
              {/* END: Right side */}
              </div>
            </Card>
          </Layout.AnnotatedSection>
          <Layout.AnnotatedSection title="Select an image" description={<TextContainer>Tall and narrow images work better in the 'Side by Side' layout, short and wide images work best in the 'Stacked' layout.<br/><br/><Link monochrome url={previewUrl} external>Preview popup</Link></TextContainer>}>
            <Card>
              {mediaLoading
                ? <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}><Spinner /></div>
                : (
                    <ResourceList
                      resourceName={{singular: 'image', plural: 'images'}}
                      items={[{filename: 'poppy.png', externalId: 'poppy/poppy_szroxc.png', width: '1200', height: '1200'}, ...mediaData.media.nodes]}
                      // selectedItems={[image]}
                      renderItem={(image) => {
                        const { externalId, filename, width, height } = image;
                        const media = <div style={{border: selectedImage === externalId ? '0.2rem solid #5c6ac4' : '0.2rem solid rgba(255,255,255,0)', borderRadius: '5px'}}><Thumbnail alt={filename} size="medium" source={`https://res.cloudinary.com/ventures-adventures/c_lfill,g_faces:auto,h_120,q_auto,w_120/v1587004871/${externalId}`}/></div>
                        return (
                          <ResourceList.Item
                            id={externalId}
                            onClick={() => setSelectedImage(externalId)}
                            media={media}
                            accessibilityLabel={`View details for ${filename}`}
                          >
                          <Stack alignment="center" distribution="fill">
                            <div style={{}}>
                              <h3>
                                <TextStyle variation="strong">{filename}</TextStyle>
                              </h3>
                              <div>width: {width}px x height: {height}px</div>
                            </div>
                            {selectedImage === externalId
                              ? <Badge status="success">Selected</Badge>
                              : null
                            }
                          </Stack>
                          </ResourceList.Item>
                        )
                      }}
                    />
                  )
              }
              <Card.Section title="Image upload">
                <DropZone accept="image/*" type="image" onDrop={handleDropZoneDrop} allowMultiple={false}>
                  {uploadLoading
                    ? <div style={{width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}><Spinner /></div>
                    : <DropZone.FileUpload />
                  }
                </DropZone>
              </Card.Section>
            </Card>
          </Layout.AnnotatedSection>
          <Layout.AnnotatedSection title="Headline" description={<TextContainer><Link monochrome url={previewUrl} external>Preview popup</Link></TextContainer>}>
            <Card sectioned>
              { loading
                ? (<SkeletonBodyText lines={1}/>)
                : (
                  <TextField
                    label="Headline"
                    value={headline}
                    onChange={handleHeadlineChange}
                  />
                )
              }
            </Card>
          </Layout.AnnotatedSection>
          <Layout.AnnotatedSection title="Description" description={<TextContainer><Link monochrome url={previewUrl} external>Preview popup</Link></TextContainer>}>
            <Card sectioned>
              {loading
                ? (<SkeletonBodyText lines={1}/>)
                : (
                  <TextField
                    label="Description"
                    value={description}
                    onChange={handleDescriptionChange}
                    multiline
                  />
                )
              }
            </Card>
          </Layout.AnnotatedSection>
        </Layout>
        </Page>
        <FooterHelp>
          Learn more about{' '}
          <Link external={true} url="https://docs.ventures-adventures.com/poppy-exit-intent-popup/settings/layout-settings">
            Layout settings
          </Link>
        </FooterHelp>
    </Page>
  )
}
