import React from 'react'
import { gql } from 'apollo-boost'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { Banner, Layout } from '@shopify/polaris'


const ACCOUNTS_ENABLED = gql`
query {
  shopDetails {
    accountsEnabled
  }
}
`

export const AccountWarningBanner = () => {
  const { loading, error, data } = useQuery(ACCOUNTS_ENABLED, {pollInterval: 30000});

  return !loading && !data.shopDetails.accountsEnabled
    ? (
      <Layout.Section fullWidth>
        <Banner title="Customer accounts are currently disabled for your Shopify store." status="critical" action={{ content: 'Fix it', url: `https://${sessionStorage.getItem('shopName')}/admin/settings/checkout`, external: true }} secondaryAction={{ content: 'Read more', url: 'https://docs.ventures-adventures.com/poppy-exit-intent-popup/poppy-getting-started/enabling-customer-accounts', external: true }}>
          <p>
            In order to send an invitation to your customers that encourages them to setup their accounts, this setting should be enabled.
          </p>
        </Banner>
      </Layout.Section>
    ) : null;
}
