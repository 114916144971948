import React, { useState, useCallback, useEffect } from 'react'
import { Button, ButtonGroup, Card, Checkbox, DisplayText, FormLayout, FooterHelp, Layout, Loading, Link, Page, RangeSlider, SkeletonBodyText, TextField, TextStyle, Toast } from '@shopify/polaris'
import { ErrorState } from '../components'
import { useHistory } from 'react-router-dom'
import { gql } from "apollo-boost";
import { useQuery, useMutation } from '@apollo/react-hooks';

import {
  AppsMajorMonotone,
  BalanceMajorMonotone,
  BehaviorMajorMonotone,
  DnsSettingsMajorMonotone,
  ImageWithTextOverlayMajorMonotone,
  ColorsMajorMonotone,
  ViewMajorMonotone
} from '@shopify/polaris-icons';

const TRIGGER_SETTINGS = gql`
query {
  shopSettings {
    enabled
    triggerSettings {
      cursorSensitivity
      activationWaitTime
      delay
      cookieExpiration
    }
  }
}
`

const SAVE_TRIGGER_SETTINGS = gql`
mutation UpdateTriggerSettings($TriggerSettings: TriggerSettingsInput!) {
  updateTriggerSettings(triggerSettings: $TriggerSettings) {
    result {
      success
      message
    }
  }
}
`

export const TriggerSettings: React.SFC = () => {
  const [unsavedChanges, setUnsavedChanges] = useState(false)
  const [cursorSensitivity, setCursorSensitivity] = useState(0)
  const [activationWaitTime, setActivationWaitTime] = useState(1000)
  const [delay, setDelay] = useState(500)
  const [cookieExpiration, setCookieExpiration] = useState(0)
  const history = useHistory()
  const { loading, error, data } = useQuery(TRIGGER_SETTINGS);
  const [ saveSettings, {loading: mutationLoading, error: mutationError, data: mutationData }] = useMutation(SAVE_TRIGGER_SETTINGS);

  useEffect(() => {
    if (window.analytics) {
        window.analytics.page('Trigger settings');
    }
  })

  useEffect(() => {
    if (data?.shopSettings?.triggerSettings?.cursorSensitivity) {
      setCursorSensitivity(data.shopSettings.triggerSettings.cursorSensitivity);
    }
    if (data?.shopSettings?.triggerSettings?.activationWaitTime) {
      setActivationWaitTime(data.shopSettings.triggerSettings.activationWaitTime);
    }
    if (data?.shopSettings?.triggerSettings?.delay) {
      setDelay(data.shopSettings.triggerSettings.delay);
    }
    if (data?.shopSettings?.triggerSettings?.cookieExpiration) {
      setCookieExpiration(data.shopSettings.triggerSettings.cookieExpiration);
    }
  }, [data])

  useEffect(() => {
    if (
      data?.shopSettings?.triggerSettings?.cursorSensitivity === cursorSensitivity
      && data?.shopSettings?.triggerSettings?.activationWaitTime === activationWaitTime
      && data?.shopSettings?.triggerSettings?.delay === delay
      && data?.shopSettings?.triggerSettings?.cookieExpiration === cookieExpiration
    ) {
      setUnsavedChanges(false)
    } else {
      setUnsavedChanges(true)
    }
  }, [cursorSensitivity, activationWaitTime, delay, cookieExpiration])

  const handleSensitivitySliderChange = useCallback(
    (value) => setCursorSensitivity(value),
    [],
  );

  const handleActivationWaitTimeChange = useCallback(
    (value) => setActivationWaitTime(value),
    []
  )

  const handleDelayChange = useCallback(
    (value) => setDelay(value),
    []
  )

  const handleCookieExpirationChange = useCallback(
    (value) => setCookieExpiration(value),
    []
  )

  if (error) return <ErrorState error={{component: "TriggerSettings", ...error }} />

  const previewUrl = `https://${sessionStorage.getItem('shopName')}/?aggreessive-mode=true&cursor-sensitivity=${cursorSensitivity}`
  const saveChanges = () => {
    saveSettings({variables: {TriggerSettings: { cursorSensitivity, activationWaitTime, delay, cookieExpiration }}})
    setUnsavedChanges(false)
  }

  return (
    <Page
      title="Trigger settings"
      icon='BehaviorMajorMonotone'
    >
      {loading || mutationLoading ? (<Loading />) : undefined}
        <Page
          title="Trigger settings"
          primaryAction={{ loading: loading || mutationLoading, disabled:  error || !unsavedChanges, content: data?.shopSettings?.enabled ? 'Publish settings' : 'Save settings', onAction: () => saveChanges() }}
          secondaryActions={[
            { content: 'Layout', onAction: () => history.push('/a/s/layout'), icon: ImageWithTextOverlayMajorMonotone},
            { content: 'Trigger', onAction: () => history.push('/a/s/trigger'), icon: BehaviorMajorMonotone, disabled: true, },
            { content: 'Theme', onAction: () => history.push('/a/s/theme'), icon: ColorsMajorMonotone },
            { content: 'Advanced', onAction: () => history.push('/a/s/advanced'), icon: DnsSettingsMajorMonotone },
            // { content: 'Integrations', onAction: () => history.push('/a/s/integrations'), icon: AppsMajorMonotone },
            { content: 'Preview popup', icon: ViewMajorMonotone, url: previewUrl, external: true }
          ]}
          breadcrumbs={[{content: 'Dashboard', onAction: () => history.push('/a/dashboard') }]}
          pagination={{
            hasPrevious: true,
            onPrevious: () => history.push('/a/s/layout'),
            previousTooltip: 'Layout settings',
            hasNext: true,
            onNext: () => history.push('/a/s/theme'),
            nextTooltip: 'Theme settings'
          }}
          forceRender={true}
          separator
        >
          {mutationData
            ? (<Toast content="Settings saved" onDismiss={() => null}/>)
            : null
          }
          {mutationError
            ? (<Toast content="Error saving settings" error onDismiss={() => null} />)
            : null
          }
          <Layout>
            <Layout.AnnotatedSection title="Sensitivity" description="Poppy fires when the mouse cursor moves close to (or passes) the top of the viewport. You can define how far the mouse has to be before Poppy fires. The higher value, the more sensitive, and the more quickly the event will fire.">
              <Card title="Cursor sensitivity" sectioned actions={[{content: 'Return to default', onAction: () => setCursorSensitivity(20)}]}>
                <FormLayout>
                  {
                    loading
                      ? (
                        <SkeletonBodyText lines={1} />
                      ) : (
                        <RangeSlider
                          label="Sensitivity"
                          value={cursorSensitivity}
                          onChange={handleSensitivitySliderChange}
                          min={0}
                          max={100}
                          helpText="Select a sensitivity value between 0 - 100. Default value is 20."
                          output
                          labelHidden={true}
                        />
                      )
                  }
                </FormLayout>
              </Card>
            </Layout.AnnotatedSection>
            <Layout.AnnotatedSection title="Activation wait time" description="By default, Poppy won't fire in the first second to prevent false positives, as it's unlikely the user will be able to exit the page within less than a second.">
              <Card title="Milliseconds before Poppy fires" actions={[{content: 'Return to default', onAction: () => setActivationWaitTime(1000)}]} sectioned>
                <FormLayout>
                  {
                    loading
                      ? (
                        <SkeletonBodyText lines={1} />
                      ) : (
                        <RangeSlider
                          label="Set a min time before Poppy fires"
                          value={activationWaitTime}
                          onChange={handleActivationWaitTimeChange}
                          min={0}
                          max={5000}
                          step={100}
                          helpText="If you want to change the amount of time that firing is surpressed for, you can pass in a number of milliseconds to delay before activating."
                          output
                          labelHidden={true}
                        />
                      )
                  }
                </FormLayout>
              </Card>
            </Layout.AnnotatedSection>
            <Layout.AnnotatedSection title="Delay" description="By default, Poppy will show the popup modal immediately. You could instead configure it to wait x milliseconds before showing the modal. If the user's mouse re-enters the body before delay ms have passed, the modal will not appear.">
              <Card title="Milliseconds to wait before triggering Poppy popup" actions={[{content: 'Return to default', onAction: () => setDelay(1000)}]} sectioned>
                <FormLayout>
                  {
                    loading
                      ? (
                        <SkeletonBodyText lines={1} />
                      ) : (
                        <RangeSlider
                          label="Delay to wait before triggering "
                          value={delay}
                          onChange={handleDelayChange}
                          min={0}
                          max={2000}
                          step={100}
                          helpText="Delay can be used to provide a 'grace period' for visitors instead of immediately presenting the modal window."
                          output
                          labelHidden={true}
                        />
                      )
                  }
                </FormLayout>
              </Card>
            </Layout.AnnotatedSection>
            <Layout.AnnotatedSection title="Cookie expiration" description="Poppy sets a cookie by default to prevent the modal from appearing more than once per user. You can add a cookie expiration (in days) using cookieExpire to adjust the time period before the modal will appear again for a user. By default, the cookie will expire at the end of the session, which for most browsers is when the browser is closed entirely.">
              <Card title="Days until the Poppy cookie expires" actions={[{content: 'Return to default', onAction: () => setCookieExpiration(7)}]} sectioned>
                <RangeSlider
                  label="Delay to wait before triggering "
                  value={cookieExpiration}
                  onChange={handleCookieExpirationChange}
                  min={0}
                  max={90}
                  step={1}
                  helpText="How many days after a user dismisses the Poppy modal should we wait before triggering the modal again. Default value is 0 days."
                  output
                  labelHidden={true}
                />
              </Card>
            </Layout.AnnotatedSection>
          </Layout>
        </Page>
        <FooterHelp>
          Learn more about{' '}
          <Link external={true} url="https://docs.ventures-adventures.com/poppy-exit-intent-popup/settings/trigger-settings">
            Layout settings
          </Link>
        </FooterHelp>
    </Page>
  )
}
