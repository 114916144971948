import React from 'react'
import { Card, DisplayText, Layout, SkeletonDisplayText, Stack } from '@shopify/polaris'
import { gql } from "apollo-boost";
import { useQuery } from '@apollo/react-hooks';
import { ErrorState } from '../components'

const STATS = gql`
query {
  stats {
    submissionCount
    triggerCount
  }
}
`


export const numberWithCommas = (x: number, decimal: boolean = false) =>
  decimal
    ? x.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    : x.toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")

export const StatsBar = () => {
  const { loading, error, data } = useQuery(STATS, { pollInterval: 30000 });
  const triggered = data?.stats?.triggerCount ? data?.stats?.triggerCount : 0
  const submissions = data?.stats?.submissionCount ? data?.stats?.submissionCount : 0
  return (
    <>
      <Layout.Section oneThird>
        <Card title="Popups displayed:" sectioned>
          <Stack distribution="fill">
            <DisplayText size="extraLarge" element="h2">
              {loading
                ? <SkeletonDisplayText size="large" />
                : <div style={{ textAlign: 'center'}}>{numberWithCommas(triggered)}</div>
              }
            </DisplayText>
          </Stack>
        </Card>
      </Layout.Section>
      <Layout.Section oneThird>
        <Card title="Form submissions:" sectioned>
          <Stack distribution="fill">
            <DisplayText size="extraLarge" element="h2">
              {loading
                ? <SkeletonDisplayText size="large" />
                : <div style={{ textAlign: 'center'}}>{numberWithCommas(submissions)}</div>
              }
            </DisplayText>
          </Stack>
        </Card>
      </Layout.Section>
      <Layout.Section oneThird>
        <Card title="Conversion rate:" sectioned>
          <Stack distribution="fill">
            <DisplayText size="extraLarge" element="h2">
              {loading
                ? <SkeletonDisplayText size="large" />
                : <div style={{ textAlign: 'center'}}>{triggered > 0 ? numberWithCommas(submissions/triggered*100, true) : 0.00}%</div>
              }
            </DisplayText>
          </Stack>
        </Card>
      </Layout.Section>
    </>
  )
}
