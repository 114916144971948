import React from 'react'
import { Route, match } from 'react-router-dom'
import { AdvancedSettings, LayoutSettings, ThemeSettings, TriggerSettings } from '../pages'

type Props = {
  match: match;
}

export const SettingsParent: React.SFC<Props> = ({ match }) => (
  <div>
    <Route path={`${match.url}/trigger`} render={() => <TriggerSettings />} />
    <Route path={`${match.url}/layout`} render={() => <LayoutSettings />} />
    <Route path={`${match.url}/theme`} render={() => <ThemeSettings />} />
    <Route path={`${match.url}/advanced`} render={() => <AdvancedSettings />} />
  </div>
)
