import React from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { Card, Layout, Heading, Icon, TextStyle } from '@shopify/polaris'
import {
  AppsMajorMonotone,
  BalanceMajorMonotone,
  BehaviorMajorMonotone,
  DnsSettingsMajorMonotone,
  ImageWithTextOverlayMajorMonotone,
  ColorsMajorMonotone
} from '@shopify/polaris-icons';

const MenuItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem;
  width: 33.33%;
  box-sizing: border-box;
  @media (max-width: 768px) {
    width: 50%;
  }
  @media (max-width: 538px) {
    width: 100%;
  }
`

const MenuItem = styled.div`
  cursor: pointer;
  border: 0.2rem dashed #dfe3e8;
  height: 18rem;
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
  width: 100%;
  box-sizing: border-box;
  text-align: center;
  color: #637381;
  @media (max-width: 538px) {
    height: 12rem;
  }
  &:hover {
    border: 0.2rem solid #5c6ac4;
    background: #F4F6F8;
    color: #212b36;
    svg {
      fill: #5c6ac4;
    }
  }
  &:active {
    background: #B3BCF5;
  }
`

const ImageWrapper = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`


export const SettingsMenu = () => {
  const history = useHistory()
  return (
    <Layout.Section fullWidth>
      <Card title="Configure your popup">
        <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', padding: '1rem' }}>
          {menuItems.map(item => (
            <MenuItemWrapper key={item.id} onClick={() => history.push(item.path)}>
              <MenuItem>
                <ImageWrapper>
                  <Icon source={item.icon} backdrop={true} />
                </ImageWrapper>
                <Heading>{item.content}</Heading>
              </MenuItem>
            </MenuItemWrapper>
          ))}
        </div>
      </Card>
    </Layout.Section>
  )
}

const menuItems = [
  {
    id: 'layout',
    content: 'Layout settings',
    accessibilityLabel: 'Trigger settings',
    icon: ImageWithTextOverlayMajorMonotone,
    panelID: 'layout',
    path: '/a/s/layout'
  },
  {
    id: 'triggers',
    content: 'Trigger settings',
    accessibilityLabel: 'Trigger settings',
    icon: BehaviorMajorMonotone,
    panelID: 'triggers',
    path: '/a/s/trigger'
  },
  {
    id: 'theme',
    content: 'Theme settings',
    accessibilityLabel: 'Theme settings',
    icon: ColorsMajorMonotone,
    panelID: 'theme',
    path: '/a/s/theme'
  },
  {
    id: 'advanced',
    content: 'Advanced settings',
    accessibilityLabel: 'Advanced settings',
    icon: DnsSettingsMajorMonotone,
    panelID: 'advanced',
    path: '/a/s/advanced'
  },
  // {
  //   id: 'integrations',
  //   content: 'Integrations',
  //   accessibilityLabel: 'Integrations',
  //   icon: AppsMajorMonotone,
  //   panelID: 'integrations',
  //   path: '/a/s/integrations'
  // },
  // {
  //   id: 'referrals',
  //   content: 'Refer a friend',
  //   accessibilityLabel: 'Referrals',
  //   icon: BalanceMajorMonotone,
  //   panelID: 'referrals',
  //   path: '/a/s/referrals'
  // }
]
