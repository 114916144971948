import React, { useState, useCallback, useEffect } from 'react'
import { Banner, Button, ButtonGroup, Card, Checkbox, DisplayText, FormLayout, FooterHelp, Layout, Loading, Link, Page, RadioButton, RangeSlider, SettingToggle, TextContainer, TextField, TextStyle, Toast } from '@shopify/polaris'
import { ErrorState } from '../components'
import { useHistory } from 'react-router-dom'
import { gql } from "apollo-boost";
import { useQuery, useMutation } from '@apollo/react-hooks';

import {
  AppsMajorMonotone,
  BalanceMajorMonotone,
  BehaviorMajorMonotone,
  DnsSettingsMajorMonotone,
  ImageWithTextOverlayMajorMonotone,
  ColorsMajorMonotone,
  ViewMajorMonotone
} from '@shopify/polaris-icons';

const ADVANCED_SETTINGS = gql`
query {
  shopSettings {
    enabled
    advancedSettings {
      aggressiveMode
      sendInvite
      cookieDomain
      cookieName
    }
  }
  shopDetails {
    accountsEnabled
  }
}
`

const SAVE_ADVANCED_SETTINGS = gql`
mutation UpdateAdvancedSettings($AdvancedSettings: AdvancedSettingsInput!) {
  updateAdvancedSettings(advancedSettings: $AdvancedSettings) {
    result {
      success
      message
    }
  }
}
`

export const AdvancedSettings: React.SFC = () => {
  const [unsavedChanges, setUnsavedChanges] = useState(false)
  const [aggressiveMode, setAggressiveMode] = useState(false)
  const [sendInvite, setSendInvite] = useState(true)
  const [cookieDomain, setCookieDomain] = useState(undefined);
  const [cookieName, setCookieName] = useState(undefined);
  const history = useHistory()
  const { loading, error, data } = useQuery(ADVANCED_SETTINGS);
  const [ saveSettings, {loading: mutationLoading, error: mutationError, data: mutationData }] = useMutation(SAVE_ADVANCED_SETTINGS);

  useEffect(() => {
    if (window.analytics) {
        window.analytics.page('Advanced settings');
    }
  })

  useEffect(() => {
    console.log(data)
    if (typeof data?.shopSettings?.advancedSettings?.aggressiveMode === 'boolean') {
      console.log('set aggressiveMode')
      console.log(aggressiveMode)
      setAggressiveMode(data.shopSettings.advancedSettings.aggressiveMode);
      console.log(aggressiveMode)
    }
    if (data?.shopSettings?.advancedSettings?.cookieDomain) {
      setCookieDomain(data.shopSettings.advancedSettings.cookieDomain);
    }
    if (data?.shopSettings?.advancedSettings?.cookieName) {
      setCookieName(data.shopSettings.advancedSettings.cookieName);
    }
    if (typeof data?.shopSettings?.advancedSettings?.sendInvite === 'boolean') {
      console.log('set sendInvite')
      console.log(sendInvite)
      setSendInvite(data.shopSettings.advancedSettings.sendInvite);
      console.log(sendInvite)
    }
  }, [data])

  useEffect(() => {
    if (
      data?.shopSettings?.advancedSettings?.aggressiveMode === aggressiveMode
      && data?.shopSettings?.advancedSettings?.sendInvite === sendInvite
      && data?.shopSettings?.advancedSettings?.cookieDomain == cookieDomain
      && data?.shopSettings?.advancedSettings?.cookieName == cookieName
    ) {
      setUnsavedChanges(false)
    } else {
      console.log('yes unsaved changed')
      console.log(`aggressiveMode: ${data?.shopSettings?.advancedSettings?.aggressiveMode} = ${aggressiveMode}`)
      console.log(`cookieDomain: ${data?.shopSettings?.advancedSettings?.cookieDomain} = ${cookieDomain}`)
      console.log(`cookieName: ${data?.shopSettings?.advancedSettings?.cookieName} = ${cookieName}`)
      console.log(`sendInvite: ${data?.shopSettings?.advancedSettings?.sendInvite} = ${sendInvite}`)
      setUnsavedChanges(true)
    }
  }, [data, aggressiveMode, sendInvite, cookieDomain, cookieName])

  const handleAggressiveModeChange = useCallback(
    (_checked, newValue) => {
      console.log(newValue)
      newValue === 'AGGRESSIVE_TRUE' ? setAggressiveMode(true) : setAggressiveMode(false)
    },
    []
  )

  const handleSendInviteChange = useCallback(
    (_checked, newValue) => newValue === 'INVITE_TRUE' ? setSendInvite(true) : setSendInvite(false),
    []
  )

  const handleCookieDomainChange = useCallback(
    (value) => setCookieDomain(value),
    [],
  );

  const handleCookieNameChange = useCallback(
    (value) => setCookieName(value),
    [],
  );

  if (error) return <ErrorState error={{component: "AdvancedSettings", ...error }} />

  const saveChanges = () => {
    saveSettings({ variables: { AdvancedSettings: { aggressiveMode, sendInvite, cookieDomain: cookieDomain ? cookieDomain : undefined, cookieName: cookieName ? cookieName : undefined }}})
    setUnsavedChanges(false)
  }
  return (
    <Page
      title="Advanced settings"
      icon="gear"
    >
      {loading || mutationLoading ? (<Loading />) : undefined}
      <Page
        title="Advanced settings"
        primaryAction={{ loading: loading || mutationLoading, disabled:  error || !unsavedChanges, content: data?.shopSettings?.enabled ? 'Publish settings' : 'Save settings', onAction: () => saveChanges() }}
        secondaryActions={[
          { content: 'Layout', onAction: () => history.push('/a/s/layout'), icon: ImageWithTextOverlayMajorMonotone},
          { content: 'Trigger', onAction: () => history.push('/a/s/trigger'), icon: BehaviorMajorMonotone },
          { content: 'Theme', onAction: () => history.push('/a/s/theme'), icon: ColorsMajorMonotone },
          { content: 'Advanced', onAction: () => history.push('/a/s/advanced'), icon: DnsSettingsMajorMonotone, disabled: true },
          // { content: 'Integrations', onAction: () => history.push('/a/s/integrations'), icon: AppsMajorMonotone },
          { content: 'Preview popup', icon: ViewMajorMonotone }
        ]}
        breadcrumbs={[{content: 'Dashboard', onAction: () => history.push('/a/dashboard') }]}
        pagination={{
          hasPrevious: true,
          onPrevious: () => history.push('/a/s/theme'),
          previousTooltip: 'Theme settings',
          hasNext: true,
          onNext: () => history.push('/a/dashboard'),
          nextTooltip: 'Integrations'
        }}
        forceRender={true}
        separator
      >
        {mutationData
          ? (<Toast content="Settings saved" onDismiss={() => null}/>)
          : null
        }
        {mutationError
          ? (<Toast content="Error saving settings" error onDismiss={() => null} />)
          : null
        }
        <Layout>
          <Layout.AnnotatedSection title="Send invite" description="When enabled Poppy will send an email with a magic link to the customer encouraging them to complete setting up their account.">
          {/* https://polaris.shopify.com/components/actions/setting-toggle */}
            <Card title={`Account invitations are currently ${sendInvite ? 'enabled' : 'disabled'}`} sectioned>
              {sendInvite && !data?.shopDetails?.accountsEnabled && !loading
                ? (
                  <>
                  <Banner title="Customer accounts are currently disabled for your Shopify store." status="critical" action={{ content: 'Fix it', url: 'https://geekpile.myshopify.com/admin/settings/checkout', external: true }} secondaryAction={{ content: 'Read more', url: 'https://docs.ventures-adventures.com/poppy-exit-intent-popup/poppy-getting-started/enabling-customer-accounts', external: true }}>
                    <p>
                      Customer accounts are currently disabled in your Shopify store. In order to send an invitation to your customers that encourages them to setup their accounts, this setting should be enabled.
                    </p>
                  </Banner>
                  <br/>
                  </>
                ) : null
              }
              <TextContainer>
                <p>
                  When enabled Poppy will send an email with a magic link to the customer encouraging them to complete setting up their account.
                </p>
                <p>
                   It is highly recommended that you enable this functionality in order to increase the number of abandoned checkouts generated inside Shopify Admin.
                </p>
                <br/>
              </TextContainer>
              <FormLayout>
                <RadioButton
                  label="Enable invitations (recommended)"
                  id="INVITE_TRUE"
                  name="sendInvite"
                  checked={sendInvite}
                  onChange={handleSendInviteChange}
                />
                <RadioButton
                  label="Disable invitations"
                  checked={!sendInvite}
                  id="INVITE_FALSE"
                  name="sendInvite"
                  onChange={handleSendInviteChange}
                />
              </FormLayout>
            </Card>
          </Layout.AnnotatedSection>
          <Layout.AnnotatedSection title="Aggressive mode" description="By default, Poppy will only fire once for each visitor. When Poppy fires, a cookie is created to ensure a non obtrusive experience.">
            <Card title={`Aggressive mode is currently ${aggressiveMode ? 'enabled' : 'disabled'}`} sectioned>
              {aggressiveMode
                ? (
                  <>
                    <Banner status="critical">
                      <p>
                        When aggressive mode is enabled the popup will be displayed <TextStyle variation="strong">every time any page is loaded, even for the same user</TextStyle>. Aggressive mode is not recommended in most circumstances
                      </p>
                    </Banner>
                    <br/>
                  </>
                ) : null
              }
              <TextContainer>
                <p>
                  Standard mode will only trigger the popup once for an entire browsing session. It will also respect the cookie duration set on the Trigger settings page.
                </p>
                <p>
                   By contrast, aggressive mode will display the popup with every single page load, even for a current session. Be careful with aggressive mode because it can irritate your customers.
                </p>
              </TextContainer>
              <br/>
              <FormLayout>
                <RadioButton
                  label={<TextStyle variation="strong">Standard Mode.</TextStyle>}
                  helpText="Users will only see one popup max per browsing session"
                  checked={!aggressiveMode}
                  id="AGGRESSIVE_FALSE"
                  name="aggreessiveMode"
                  onChange={handleAggressiveModeChange}
                />
                <RadioButton
                  label={<TextStyle variation="strong">Aggressive mode</TextStyle>}
                  helpText="Users can trigger a popup every single time they match the trigger criteria. Aggressive mode is not recommended"
                  id="AGGRESSIVE_TRUE"
                  name="aggreessiveMode"
                  checked={aggressiveMode}
                  onChange={handleAggressiveModeChange}
                />
              </FormLayout>
            </Card>
          </Layout.AnnotatedSection>

          <Layout.AnnotatedSection title="Cookie settings" description="Poppy sets a cookie by default to prevent the modal from appearing more than once per user.">
            <Card>
              <Card.Section title="Cookie domain">
                <TextContainer>
                  <p>
                    Poppy sets a cookie by default to prevent the modal from appearing more than once per user. You can add a cookie domain using cookieDomain to specify the domain under which the cookie should work.
                  </p>
                  <p>
                    By default, no extra domain information will be added. If you need a cookie to work also in your subdomain (like blog.example.com and example.com), then set a cookieDomain such as .example.com (notice the dot in front).
                  </p>
                  <br/>
                </TextContainer>
                <TextField
                  label="Cookie domain"
                  value={cookieDomain}
                  onChange={handleCookieDomainChange}
                />
              </Card.Section>
              <Card.Section title="Cookie name">
                <TextContainer>
                  <p>
                    You can specify a cookie name for custom software development (you will probably never need this)
                  </p>
                </TextContainer>
                <TextField
                  label="Cookie name"
                  value={cookieName}
                  onChange={handleCookieNameChange}
                />
              </Card.Section>
            </Card>
          </Layout.AnnotatedSection>
        </Layout>
      </Page>
      <FooterHelp>
        Learn more about{' '}
        <Link external={true} url="https://docs.ventures-adventures.com/poppy-exit-intent-popup/settings/advanced-settings">
          Layout settings
        </Link>
      </FooterHelp>
    </Page>
  )
}
