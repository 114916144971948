import React, { ReactElement } from 'react'
import * as PropTypes from 'prop-types'
// import { Query } from 'react-apollo'
import { gql } from 'apollo-boost'
import { useQuery } from '@apollo/react-hooks';
import { LoadingPage } from '../pages'
import { ErrorState } from './'


type Props = {
  token: string | false | null;
  shopName: string | false | null;
}


const QUERY = gql`
query Token($token: String) {
  token(token: $token) {
    ok
  }
}
`

export const AuthReloader: React.FC<Props> = ({ token, shopName, children }) => {
  const { loading, error, data } = useQuery(QUERY, {
    variables: {
      token
    }
  });

  if (loading) return <LoadingPage />;
  if (error) return <ErrorState error={{component: "AuthReloader", ...error}} />

  if (data.token.ok) {
    if (process.env.NODE_ENV === 'development') console.log(token);
    // @ts-ignore: Unreachable code error
    if (typeof window.tap !== 'undefined') window.tap('customer', shopName, {always_callback: true}, (err, res) => {
      // console.log('err:')
      // console.log(err)
      // console.log('res:')
      // console.log(res)
    });
    return <>{children}</>;
  }
  return (<h1>You have been logged out. Please refresh your browser</h1>);
}
