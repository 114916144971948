import React, { useState } from 'react'
import { Card, DisplayText, Heading, Layout, Loading, Link, Stack, TextContainer, Toast, SkeletonBodyText, SkeletonDisplayText } from '@shopify/polaris'
import { gql } from "apollo-boost";
import { useQuery, useMutation } from '@apollo/react-hooks'
import { PoppyColor, PoppyGreyscale } from '../assets'

const ENABLED_QUERY = gql`
query {
  shopSettings {
    enabled
  }
}
`

const TOGGLE_MUTATION = gql`
mutation TogglePoppy($Enabled: Boolean) {
  togglePoppy(enabled: $Enabled) {
    result {
      success
      message
    }
  }
}
`

export const EnableCard = () => {
  const { loading, error, data, refetch } = useQuery(ENABLED_QUERY);
  const [togglePoppy, { loading: mutationLoading, error: mutationError, data: mutationData }] = useMutation(TOGGLE_MUTATION);

  const enabled = !loading && data?.shopSettings?.enabled;
  const Title = loading
    ? <DisplayText>Your Poppy Popup is currently <SkeletonDisplayText size="small"/></DisplayText>
    : <DisplayText>Your Poppy Popup is currently {enabled ? 'enabled' : 'disabled'}</DisplayText>

  const toggle = async (enabled: boolean) => {
    await togglePoppy({ variables: { Enabled: enabled }})
    refetch()
  }

  return (
    <Layout.Section fullWidth>
      {loading || mutationLoading ? (<Loading />) : undefined}
      {mutationData
        ? (<Toast content="Settings saved" onDismiss={() => null}/>)
        : null
      }
      <Card
        title={<DisplayText>Your Poppy Popup is currently {enabled ? 'enabled' : 'disabled'}</DisplayText>}
        actions={[{content: 'Preview popup', url: `https://${sessionStorage.getItem('shopName')}/?poppy-preview`, external: true}]}
        primaryFooterAction={{content: 'Enable popup', disabled: enabled, onAction: () => toggle(true), loading }}
        secondaryFooterAction={{content: 'Disable popup', disabled: !enabled, onAction: () => toggle(false), loading }}
        sectioned
      >
        <Stack wrap={false}>
          <Stack.Item fill>
            <TextContainer>
              <Heading>Poppy increases abandoned checkouts</Heading>
              <p>
                Poppy works within Shopify, not against it, to create more Customers inside of Shopify Admin.
              </p>
              <p>
                Poppy drives more people to create an account and log into your store. Logged in sessions increase the amount of carts, and reduce friction at checkout.
              </p>
              <p>
                Read more about our philosophy and approach towards product on our <Link url="https://docs.ventures-adventures.com/poppy-exit-intent-popup/philosophy" external={true}>help documentation</Link>
              </p>
              <p>
                If you are ever stuck or feel that something should be better, we want to hear about it. Email us directly at <Link url="mailto:jordan@ventures-adventures.com" external={true}>jordan@ventures-adventures.com</Link>
              </p>
            </TextContainer>
          </Stack.Item>
          <Stack.Item>
            <img src={enabled ? PoppyColor : PoppyGreyscale} style={{ width: '20vw'}} />
          </Stack.Item>
        </Stack>
      </Card>
    </Layout.Section>
  )
}
