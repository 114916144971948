import React, { useCallback, useEffect, useState } from 'react'
import { Banner, Button, CalloutCard, Card, FooterHelp, Layout, Icon, Link, Page, ResourceList, Stack, Tabs, TextStyle } from '@shopify/polaris'
import { ChevronRightMinor, BehaviorMajorMonotone } from '@shopify/polaris-icons';

import Tour from 'reactour'
import { useHistory } from 'react-router-dom'
import { AccountWarningBanner, Announcements, EnableCard, ProductResourceList, SettingsMenu, StatsBar } from '../components'
import { SearchSVG } from '../assets'

type Props = {
  shopName?: string;
}

export const Dashboard: React.SFC<Props> = ({ shopName }) => {
  const [showTour, setShowTour] = useState(window.localStorage.getItem("showTour") === 'FALSE' ? false : true)
  const [selected, setSelected] = useState(0);
  const history = useHistory()

  const handleTabChange = useCallback(
    (selectedTabIndex: number) => setSelected(selectedTabIndex),
    [],
  );

  useEffect(() => {
    if (window.analytics) {
        window.analytics.page('Dashboard');
    }
  })
  return (
    <Page
      title="Dashboard"
      icon="home"
    >
      <Layout>
        <Announcements />
        <AccountWarningBanner />
        <StatsBar />
        <EnableCard />
        <SettingsMenu />

      </Layout>
      <FooterHelp>
        Visit the{' '}
        <Link url="https://app.gitbook.com/@ventures-adventures/s/upc-code-manager/poppy-exit-intent-popup/poppy-getting-started">
          Poppy help documenation
        </Link>
      </FooterHelp>
    </Page>
  )
}
