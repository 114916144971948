import React from 'react'

type Props = {
  fill?: string;
}

export const ColorPickerIcon: React.FC<Props> = ({ fill }) => (
  <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="10" cy="10" r="8" fill={fill ? fill : "#C4C4C4"} stroke="#C4C4C4" stroke-width="2"/>
  </svg>
)
