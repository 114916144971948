import React, { ReactElement, useState } from 'react'
import { Route } from 'react-router-dom'
import { Avatar, Badge, Pagination, ResourceList, Stack, Tabs, TextStyle } from '@shopify/polaris'
import { Query } from 'react-apollo'
import { gql } from 'apollo-boost'
import { ErrorState, ResourceListFooter } from './'
import { IProduct } from 'shopify-api-node'

const PRODUCT_QUERY = gql`
query ProductListQuery($page: Int) {
  products(page: $page) {
    nodes {
      id
      title
      variants {
        id
        title
        barcode
      }
      images {
        position
        src
      }
      variantStats {
        total
        active
        needed
      }
    }
  }
}
`


type Props = {

}

export const ProductResourceList: React.SFC<Props> = ({}) => {
  const [page, setPage] = useState(1)
  const resourceName = {
    singular: 'product',
    plural: 'products',
  };

  return (
    <Query query={PRODUCT_QUERY} variables={{ page }}>
    {({loading, error, data: { products }, refetch}) => {
      if (error) return <ErrorState />
      return (
        <Tabs tabs={[{id: "all-products", content: "All Products"}]} selected={0}>
          <ResourceList
            resourceName={resourceName}
            items={products && products.nodes ? products.nodes : []}
            renderItem={(item) => <RenderItem product={item} />}
            loading={loading}
          />
          <ResourceListFooter>
            <Pagination
              hasPrevious={page > 1 ? true : false}
              onPrevious={() => {
                setPage(page-1)
              }}
              hasNext={products && products.nodes.length === 50 ? true : false}
              onNext={() => {
                setPage(page + 1)
              }}
            />
          </ResourceListFooter>
        </Tabs>
      )
    }}
    </Query>
  )
}

interface EnrichedProduct extends IProduct {
  variantStats?: VariantStats;
}

type VariantStats = {
  total?: number;
  active?: number;
  needed?: number;
  progress?: number;
}

type RenderItemProps = {
  product: EnrichedProduct;
}

const RenderItem: React.SFC<RenderItemProps> = ({
  product: {
    id,
    title = 'Unnamed product',
    images,
    variantStats: { total, needed, active } = {}
  }
}) => {
  return (
    <Route render={({ history }) => (
      <ResourceList.Item
          id={id ? String(id) : title}
          accessibilityLabel={`View details for ${title}`}
          onClick={() => history.push(`/a/products/${id}`)}
          media={<Avatar size="large" source={images && images[0] ? images[0].src : undefined}/>}
        >
        <h3>
          <TextStyle variation="strong">{title}</TextStyle>
        </h3>
        <div>{`${total} product variant${total !== 1 ? 's' : ''}`}</div>
        <VariantBadges needed={needed} active={active} />
      </ResourceList.Item>
    )}/>

  )
}

const VariantBadges: React.SFC<VariantStats> = ({ total, active, needed}) => {
  return (
    <Stack>
      <Badge size="small" status={needed === 0 ? "success" : "warning"}>{`${String(needed)} barcode${needed === 1 ? '' : 's'} needed`}</Badge>
      <Badge size="small">{`${String(active)} variant${active === 1 ? '' : 's'} with barcodes`}</Badge>
    </Stack>
  )
}
